import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "元フロントエンドエンジニアから見た Android 開発",
  "date": "2017-07-28T03:50:00.000Z",
  "slug": "entry/2017/07/28/125000",
  "tags": ["medley"],
  "hero": "./2017_07_28.png",
  "heroAlt": "android"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`今回の内容について`}</h1>
    <p>{`みなさん、こんにちは。開発本部でオンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」の開発を担当している`}<a parentName="p" {...{
        "href": "https://profile.hatena.ne.jp/Layzie/"
      }}>{`平木`}</a>{`です。`}</p>
    <p>{`弊社では、インフラ・サーバ・フロントで役割を区切らず、全ての開発メンバーが必要に応じてスキルを広げながら開発に取り組んでいます。 自分も入社前はフロントエンド専門のエンジニアでしたが、入社後はそれに加えて Rails を使ったサーバサイドの開発や、Swift を使った iOS アプリ開発、 そして、現在メインにやっている Android 開発と一通りのプラットフォームや言語を使って開発するようになっています。`}</p>
    <p>{`エンジニアが自身のスキルを広げる場合、自分の経験や知識を応用して、新しいプラットフォームを理解していくということが多いと思います。`}</p>
    <p>{`元フロントエンジニアの経験を持っている自分が Android 開発に関わってみて `}<strong parentName="p">{`やりやすかった部分`}</strong>{`や`}<strong parentName="p">{`つらかった部分`}</strong>{`などを書いていこうと思います。同じような立場でこれから開発しようと思う方には少しお役に立てるかもしれません。`}</p>
    <h1>{`やりやすかった部分`}</h1>
    <h2>{`Android Studio のありがたさ`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170727/20170727194320.png",
      "alt": "20170727194320.png"
    }}></img>
    <p>{`いきなり IDE の話になっていますが。やはり Android Studio のオールインワン感がとても便利です。Web フロントエンドの開発だと IDE といえば WebStorm など使ったことがありましたが、ライブラリを含めた補完周りなどはやっぱり Java などでの開発した方が IDE は力を発揮しやすいんではないかと思いました。`}</p>
    <p>{`とりあえずほとんど設定をいじらなくても、補完やドキュメントの参照ができるのはラクです。また JS などで変数に変換してもあんまり便利さが湧きませんが、 Java の場合だと勝手に対応した型もつけてくれたりと至れり尽くせりだと思いました。`}</p>
    <p>{`ゲッターセッターなども手作業でやると単純作業になりますが、⌘N で勝手に展開してくれますし、Java での開発の冗長な部分をあまり感じないで開発できるのが凄いです。`}</p>
    <p>{`エミュレータなども自分が使い始めたときは既に起動も高速な感じでしたし、エミュレータの管理なども Android Studio 内で完結するのであまり迷ったりすることがないです。また初心者に優しいなと思ったのが、Activity など作るのにテンプレートがちゃんと用意されてるのであまりファイル構成が分かってない初めの頃でもちゃんと画面を作ることができるところです。`}</p>
    <h2>{`ビルドシステムが分かりやすい`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170727/20170727194345.png",
      "alt": "20170727194345.png"
    }}></img>
    <p>{`ご存知 Gradle ですが、通常の使い方してる分には(あまり Gradle でがんばらなければ)、WebPack とかよりも書きやすいよねと思います。もちろん目的が違いますが。`}</p>
    <p>{`フロントエンド開発でよく陥りがちな「設定ファイルなんだかプログラムなのか分からないくらい作られた」というような設定ファイルにはなりにくいんじゃないかと感じています。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`build.gradle`}</code>{` で設定した環境変数などもソースからさっと使うことができますし、良く考えられてるなーと思いました。`}</p>
    <p>{`公式のライブラリであれば、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`build.gradle`}</code>{` で lint としてライブラリの更新があることが分かるのも親切です。サードパーティのライブラリもこれがされてるととても嬉しいんですが、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Analyze -> Run Inspection by Name -> Newer Library Versions Available`}</code>{` で見てくれるんでガマンしてます。`}</p>
    <h2>{`View 部分の作り方がフロントエンドに似ているので取っつきやすい`}</h2>
    <p>{`これは HTML / CSS を書いていた人間からすると大変に敷居が低いと思いました。`}</p>
    <p>{`使うパーツは最初はどんなものがあるのか分からないのでドキュメント引いたり、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Design`}</code>{` タブからパーツを選択したりして View を作ってましたが、HTML を組む感覚でガンガンと作っていけました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170727/20170727195637.png",
      "alt": "20170727195637.png"
    }}></img>
    <p>{`基本的には HTML タグのなかで `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`style`}</code>{` 属性付けていくみたいな感じです。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin`}</code>{` とか `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{` とかも同じですし、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`textAlign`}</code>{` なんかもあるので初期のころは本当に助かりました。`}</p>
    <p>{`もちろん `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`AppBarLayout`}</code>{` とか `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Toolbar`}</code>{` とか `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Theme`}</code>{` など Android 独自の概念はありますがそういった差分的なものは知識として覚えていけば良いだけなので、フロントエンドに慣れていると大変に View 作るのがラクだと感じます。`}</p>
    <p>{`iOS の StoryBoard は未だにつらい。`}</p>
    <h2>{`ググったときに古めの情報を参考にしても問題が解決できる後方互換性の高さ`}</h2>
    <p>{`ここ数年フロントエンドの技術的な流れが早すぎるというのは一般的に良く言われていますが、良い意味で Android は古い情報もちゃんと使えるというのはとても助かりました。`}</p>
    <p>{`一番古いので 2011 年くらいの情報だったら使えました。(もちろん廃止されてる…というのも多いのですが)`}</p>
    <p>{`初めのうちは `}<strong parentName="p">{`フロントエンドだとこうやるけど Android ではどうするんだろう`}</strong>{` というような疑問が多いと思うのですが、特に View に関わるような問題などは古い記述でも問題なく使える後方互換性の高さのおかげで「ググったように書いたのに、今は使えない…」というストレスからかなり開放されます。`}</p>
    <h1>{`つらかった部分`}</h1>
    <p><strong parentName="p">{`ライブラリや画像を気軽に入れにくい`}</strong></p>
    <p>{`これついては、フロントエンドの JS などでも別に気軽にさくさくとライブラリを入れていたわけではないですが、いざ入れようとすると Android(というよりもネイティブアプリ全般か)の場合全て APK の容量増加という自体に直結するので中々気をつかいます。`}</p>
    <p>{`さすがにゲームでもないのに容量重いアプリにするとそもそもダウンロードしてもらえるかどうかも怪しくなります。パッケージする画像なども多用するような仕様にしないようにしています。`}</p>
    <p>{`あと最初のころにビルドできずに困るわ…と思って必死に対応を調べた `}<a parentName="p" {...{
        "href": "https://developer.android.com/studio/build/multidex.html"
      }}>{`64K 参照制限`}</a>{` も一回設定してしまえば何てことはないんですが、割と初見殺しだと思います。`}</p>
    <p>{`また実際にプロダクション用にビルドするとなるとフロントエンドでもおなじみのソースの難読化をするのですがその際にライブラリのなかで難読化しちゃいけないなどの指定をしないと余裕で動かなくなります。`}</p>
    <p>{`ということで各ライブラリを使うときには`}<a parentName="p" {...{
        "href": "https://www.guardsquare.com/en/proguard"
      }}>{`ProGuard`}</a>{`の設定をしないといけないのですが、これも気軽にライブラリ使おうと中々ならない理由でした。`}</p>
    <p><strong parentName="p">{`バッググラウンドプロパティ関連の貧弱さ`}</strong></p>
    <p>{`良い点のところで HTML / CSS と View の作りが似ているので幸せと書いたんですが、1 つだけ許せないことがありまして、それが `}<strong parentName="p">{`バックグラウンドプロパティ関連のプロパティの貧弱さ`}</strong>{` というものです。`}</p>
    <p>{`どういうことかというと…例えばこんな感じのラベル作るとします。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170727/20170727194414.png",
      "alt": "20170727194414.png"
    }}></img>
    <p>{`HTML / CSS だったら楽勝だと思います。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`radius`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`診察待ち`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "language-css"
      }}><code parentName="pre" {...{
          "className": "language-css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.radius`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 12px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`padding`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 4px 12px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`background-color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #89c8ba`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #ffffff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`しかし Android の場合は`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`TextView`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@+id/upcoming_list_status`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`textSize`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`14sp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`100dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`wrap_content`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`textAlignment`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`center`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`background`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@drawable/label_status_upcoming`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`textColor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`#FFFFFF`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`text`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`診察待ち`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <p>{`とテキスト作ったうえで、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`background`}</code>{` の指定先のベクターアセットを作ってやらないといけません。
このアセットが `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{` や `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`background-color`}</code>{` など指定されているというものになります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token prolog"
          }}>{`<?xml version="1.0" encoding="utf-8"?>`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`selector`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`xmlns:`}</span>{`android`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://schemas.android.com/apk/res/android`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`item`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`shape`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`shape`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`rectangle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`stroke`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`1dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`color`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@color/label_status_upcoming`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`corners`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`bottomLeftRadius`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`12dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`bottomRightRadius`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`12dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`topLeftRadius`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`12dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`topRightRadius`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`12dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`solid`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`color`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@color/label_status_upcoming`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
            `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`padding`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`left`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`12dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`right`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`12dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`top`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`2dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`bottom`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`2dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`shape`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`item`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`selector`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`とても面倒です。`}</p>
    <h2>{`リストがつらい`}</h2>
    <p>{`配列になっているデータを取り出してリストとして表示するという状況、良くあると思います。
フロントエンドなら `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<ul/>`}</code>{` で囲んだなかの `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<li/>`}</code>{` にデータを `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`forEach()`}</code>{` やら `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`map()`}</code>{` やら使って作っていくのが常道ですが、Android は手順が多いので面倒です。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170727/20170727194509.png",
      "alt": "20170727194509.png"
    }}></img>
    <p>{`このようなお知らせのリストを作るのに…`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationItemHelper`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` patientName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationItemHelper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` patientName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`message `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`link `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`patientName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` patientName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getMessage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getLink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` link`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getPatientName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` patientName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`リストに表示するためのクラスを作ってあげて、`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationListAdapter`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ArrayAdapter`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`NotificationItemHelper`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` layoutResource`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationListAdapter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Context`}</span>{` context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` resource`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`NotificationItemHelper`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` objects`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`context`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` resource`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` objects`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`layoutResource `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` resource`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@NonNull`}</span>{`
    `}<span parentName="code" {...{
            "className": "token annotation punctuation"
          }}>{`@Override`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`View`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`int`}</span>{` position`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`View`}</span>{` convertView`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ViewGroup`}</span>{` parent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`View`}</span>{` view `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` convertView`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`view `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`LayoutInflater`}</span>{` layoutInflater `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`LayoutInflater`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`from`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getContext`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            view `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` layoutInflater`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`inflate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`layoutResource`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationItemHelper`}</span>{` notificationItemHelper `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`position`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`notificationItemHelper `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TextView`}</span>{` message `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`TextView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findViewById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`R`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`notification_list_message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`TextView`}</span>{` patientName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`TextView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`findViewById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`R`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`notification_list_patient_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`message `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setText`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`notificationItemHelper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getMessage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

            `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`patientName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                patientName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setText`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`notificationItemHelper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getPatientName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`それを View に表示させるための Adapter というものを作り、`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token prolog"
          }}>{`<?xml version="1.0" encoding="utf-8"?>`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`LinearLayout`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`xmlns:`}</span>{`android`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://schemas.android.com/apk/res/android`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@+id/upcoming_list`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`match_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`match_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`paddingStart`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@dimen/spacing_small`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`paddingEnd`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@dimen/spacing_small`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`orientation`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`horizontal`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`LinearLayout`}</span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`wrap_content`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_weight`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`1`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`paddingTop`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@dimen/spacing_small`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`paddingBottom`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@dimen/spacing_small`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`orientation`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`vertical`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`TextView`}</span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@+id/notification_list_message`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@style/TextBoldStyle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`match_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`wrap_content`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_marginBottom`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@dimen/spacing_xsmall`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`

        `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`TextView`}</span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@+id/notification_list_patient_name`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@style/TextNormalStyle`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`match_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`wrap_content`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_marginBottom`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@dimen/spacing_xsmall`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
            `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`textColor`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@color/text_color_secondary`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`LinearLayout`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ImageView`}</span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`wrap_content`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`wrap_content`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_gravity`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`end|center_vertical`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
        `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@drawable/ic_keyboard_arrow_right_black_24dp`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`LinearLayout`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`実際のリストの内容を xml で作ってあげて、`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "xml"
    }}><pre parentName="div" {...{
        "className": "language-xml"
      }}><code parentName="pre" {...{
          "className": "language-xml"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`ListView`}</span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`@+id/notification_list`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_width`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`match_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
    `}<span parentName="span" {...{
              "className": "token attr-name"
            }}><span parentName="span" {...{
                "className": "token namespace"
              }}>{`android:`}</span>{`layout_height`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`match_parent`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre></div>
    <p>{`リストのラッパーを指定して`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`NotificationItemHelper`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` notificationItemList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ArrayList`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ListView`}</span>{` notificationListView `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`findViewById`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`R`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`notification_list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`List`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`NotificationResponse`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` notifications `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` notificationsResponse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getNotificationResponses`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Notification`}</span>{` notification `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` notifications`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationItemHelper`}</span>{` item `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationItemHelper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
            notification`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getMessage`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            notification`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getLink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            notification`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getPatientName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    notificationItemList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationListAdapter`}</span>{` notificationListAdapter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`NotificationListAdapter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`this`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`R`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`layout`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`notification_layout`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` notificationItemList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
notificationListView`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setAdapter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`notificationListAdapter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`ここまでやってようやくリストが表示されます。未だに面倒だなーと思います。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`色々と細かい例を上げましたが、いかがでしょうか。`}</p>
    <p>{`今回は書いてませんが、ライブラリも JS とは考え方違って面白いなあと思うものがあります。特に View パーツを簡単に選んだり、イベントを書くことができる`}<a parentName="p" {...{
        "href": "https://jakewharton.github.io/butterknife/"
      }}>{`ButterKnife`}</a>{`やバリデーションライブラリの`}<a parentName="p" {...{
        "href": "https://github.com/ragunathjawahar/android-saripaar"
      }}>{`Android Saripaar`}</a>{`のように Java のアノテーションを付けるだけで簡単に使える部分などは Java に触れてこなかった身としては新鮮でした。`}</p>
    <p>{`全体的な印象としてはプラットフォーム特有のクセなどはもちろんありますが、フロントエンドエンジニアでもかなり取り組みやすいのではないかと感じています。何より Android 実機で自分が作ったものが動いてるのを見るとブラウザで自分が作ったサイトが動いているというのとは一味違った達成感があります。`}</p>
    <p>{`もちろん開発していく内に、たとえば JS にはないスレッドの概念が立ち塞がったり Android 特有のライフサイクルに悩まされたりもしますが、公式のドキュメントも充実していますし、Stack Overflow など見て解決することがかなり多いです。`}</p>
    <p>{`これを読んで Android 開発やってみようと思っていただけたら良いなと思います。
もっといろいろ知りたいという方は「`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley"
      }}>{`話を聞いてみたい`}</a>{`」ボタンを押してもらえれば！`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは医療業界に存在する課題に IT を駆使して取り組んでいきたいデザイナー・エンジニアを募集中です。
皆さまからのご応募お待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/creator-story.html"
      }}>{`https://www.medley.jp/team/creator-story.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      